import React from "react";
import Content from "../Content";
import PropTypes from "prop-types";
import {Link} from "gatsby";

const ServiceEntryTemplate = ({
  title,
  meta_title,
  meta_desc,
  content,
  subOfferings,
})  => {
  return (

    <div>
      <section className="hero is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h1 className="title">{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <div className="content serviceContent">
                  {content[0].type === "h2" ? content[0] : "" }
                  {content[1].type ==="p" ? content[1] : ""}
                  <div className="align-center">
                <Link className="button center" to="/contact/">Contact Us</Link>
                </div>
                {content[2].type ==="hr" ? content[2] : ""}
                {content[3]}
                {content[4]}
                {content[5]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
  
};

ServiceEntryTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.array,
  contentComponent: PropTypes.func,
};

export default ServiceEntryTemplate;
