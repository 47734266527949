import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { HTMLContent } from "../components/Content";
import ServiceTemplate from "../components/ServiceEntryTemplate";
import SE0 from "../components/SEO";
import Layout from "../components/Layout";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const ServicePage = ({ pageContext }) => {
  const serviceInfo = pageContext;
  return (
    <Layout>
      <SE0
        title={serviceInfo.title}
        meta_title={serviceInfo.metaTitle}
        meta_desc={serviceInfo.metaDescription}
        slug={serviceInfo.slug}
        contentType="website"
      />

      <ServiceTemplate
        content={documentToReactComponents(serviceInfo.content.json)}
        contentComponent={HTMLContent}
        meta_title={serviceInfo.metaTitle}
        meta_desc={serviceInfo.metaDescription}
        title={serviceInfo.title}
      />
    </Layout>
  );
};

ServicePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ServicePage;
